<template>
  <div class="privacyPolicy">
    <div class="privacyPolicy-content">
      <h1>Згода на обробку персональних даних</h1>
      <p>Я, ознайомлений з Політикою конфіденційності з обробки та захисту персональних даних Сервісом - АВТОМАТИЗОВАНА СИСТЕМА ОЦІНКИ КОМПЕТЕНТНОСТІ МОРЯКА Порталу «CREW» (далі – Політика конфіденційності) та надаю свою добровільну, інформовану та однозначну згоду на необмежений строк на:</p>
      <p>обробку моїх персональних даних в розумінні статті 2 Закону України «Про захист персональних даних» в обсязі, визначеному Політикою конфіденційності, за допомогою програмних засобів АВТОМАТИЗОВАНОЇ СИСТЕМИ ОЦІНКИ КОМПЕТЕНТНОСТІ МОРЯКА Порталу «CREW» (далі - Сервіс), відповідно до мети збору та обробки персональних даних, визначеної в Політиці конфіденційності (пункт 1 частини другої статті 7 та пункт 1 частини першої статті 11 Закону України «Про захист персональних даних»).</p>
      <ul>
        <li>використання моїх персональних даних в обсязі, визначеному в Політиці конфіденційності, що передбачає будь-які дії володільця персональних даних щодо обробки цих даних, дії щодо їх захисту, а також дії щодо надання часткового або повного права обробки персональних даних іншим суб’єктам відносин, пов’язаних із персональними даними (стаття 10 Закону України «Про захист персональних даних»);</li>
        <li>поширення моїх персональних даних в обсязі, визначеному в пункті 3 Повідомлення про обробку персональних даних, що передбачає дії володільця персональних даних щодо передачі відомостей про фізичну особу (стаття 14 Закону України «Про захист персональних даних»);</li>
        <li>доступ третіх осіб до моїх персональних даних в обсязі, визначеному в пункті 3 Повідомлення про обробку персональних даних (стаття 16 Закону України «Про захист персональних даних»).</li>
        <li>на оброблення та зберігання таких персональних даних на серверах відповідних організацій, фізичне зберігання даних - на території України, а також, за необхідності, їх передачу третім особам в порядку і на умовах, передбачених законодавством України, Ліцензійним договором та Політикою конфіденційності.</li>
      </ul>
      <p>Згідно з цією Згодою мої персональні дані можуть передаватися до/з інших електронних інформаційних ресурсів виключно з метою їх обробки (збору), визначеною Політикою конфіденційності без додаткового повідомлення мене, як суб’єкта персональних даних, про таке передання.</p>
      <p>Я надаю беззаперечну згоду та погоджуюсь з тим, що Сервіс має право отримувати та обробляти персональні дані з відкритих реєстрів та на основі запитів та/або прямого доступу до баз даних і будь-яких реєстрів, в тому числі, але не обмежуючись, на підставі угод про обмін інформацією між Сервісом та державними органами, організаціями, підприємствами будь-якої форми власності з метою формування бази даних Сервісу та надання мені можливості максимального користування функціоналом Сервісу.</p>
      <p>Я усвідомлюю, що в результаті збою в роботі Сервісу, вірусної або хакерської атаки, технічних несправностей та будь-яких інших форс-мажорних обставин мої персональні дані можуть стати доступними й іншим особам. Я розумію і погоджуюсь з тим, що не буду висувати претензії до Сервісу або Ліцензіара у зв’язку з цим.</p>
      <p>Цим я підтверджую, що належним чином повідомлений(-а) про мету обробки моїх персональних даних відповідно до Політики конфіденційності та ознайомлений(-а) з її змістом .</p>
      <p>Мета та умови обробки моїх персональних даних мені зрозумілі та я погоджуюся з ними.</p>
      <p>Ця Згода надається мною на строк, необхідний для досягнення мети збору та обробки персональних даних, визначеної в Політиці конфіденційності.</p>
      <p>Всі вище зазначені дії дозволяю проводити без моєї додаткової письмової або усної згоди та без повідомлення мене у будь-якій формі.</p>
      <p>Зобов'язуюсь, при зміні моїх персональних даних надавати у найкоротший термін уточнену інформацію та подавати відповідні документи для внесення моїх нових особистих даних.</p>
      <p>Ця Згода є чинною з моменту її надання.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Terms',
  beforeCreate () {
    this.$store.commit('setActivePage', { name: 'terms', access: false })
  }
}
</script>
